import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from '@mui/material/Typography';
import { theme } from "../../../theme-colors/theme";
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

// icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IBird, ITour, getImagesByTourId, getTopBirdById } from "../../../api/exploreApi";
import { readImageFromS3 } from "../../../functions/readImageFromS3";
import { useQuery } from "@tanstack/react-query";

function TourCard(data: ITour) {


  const { isLoading: birdLoading, data: tourBirds } = useQuery<IBird[]>({
    queryKey: ["topbirds" + data.id],
    queryFn: () => getTopBirdById(data.id),
  });


  const navigate = useNavigate();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: "20px"
    }}
      onClick={() => {
        navigate(`/tour/${data.id}`);
      }}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          component="img"
          src={
            birdLoading
              ? "./store/logo_w.png"
              : readImageFromS3(tourBirds?.[1].bucketUrl || "./store/logo_w.png")
          }
          alt="tour"
          sx={{
            width: { sm: "600", md: "100%" },
            height: { sm: "300", md: "50vh" },
            objectFit: "cover", //none
          }} />
      </Box>
      <Box
        sx={{
          my: "20px",
          width: "100%",
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography fontFamily="MazzardM-Medium"
            sx={{
              fontSize: { xs: "14px", sm: "14px", xl: "26px" },
            }}>
            {data.title}
          </Typography>
          <Typography fontFamily="MazzardL-Light"
            sx={{
              fontSize: { xs: "12px", sm: "14px", xl: "18px" },
            }}>
            {data.description.pace}
          </Typography>
        </Box>


        <Button
          sx={{
            color: theme.backgroundColor,
            backgroundColor: theme.btnColor,
            borderRadius: "0",
            display: { xs: "none", md: "unset" },
            width: "250px",
            height: "45px",
            aspectRatio: '292 / 60',
            fontSize: { xs: "8px", md: "15px" },
            fontFamily: 'MazzardM-Bold',
            '&:hover': {
              backgroundColor: '#D9D9D9'
            },
          }}
          onClick={() => {
            navigate(`/tour/${data.id}`);
          }}
        >
          EXPLORE
        </Button>
      </Box>
      <Divider
        sx={{
          width: "100%",
          border: 1,
          color: theme.grayColor
        }}
      />
    </Box >
  );
}
export default TourCard;
