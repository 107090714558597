import { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Button, Card, Container, Divider, IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import "./components/Swiper.css";

import dayjs, { Dayjs } from "dayjs";

// icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery } from "@tanstack/react-query";
import { IBird, ITour, getImagesByTourId, getTopBirdById, getTourById } from "../../../api/exploreApi";
import { useParams } from "react-router-dom";
import TourTab from "./components/TourTab";
import { readImageFromS3 } from "../../../functions/readImageFromS3";


function TourPost() {
  const { tourId } = useParams();

  const { isLoading: tourLoading, data: tour } = useQuery<ITour>({
    queryKey: [{ tourId }],
    queryFn: () => getTourById(tourId),
  });


  const { isLoading: birdLoading, data: topBirdData } = useQuery<IBird[]>({
    queryKey: ["tp" + tourId],
    queryFn: () => getTopBirdById(tourId),
  });



  // Calendar
  const [startDate, setStartDate] = useState<Dayjs | null>();
  const [endDate, setEndDate] = useState<Dayjs | null>();
  if (tourLoading || birdLoading) {
    return <div>Loading...</div>; // 로딩 중일 때 처리
  }

  if (!tour) {
    return <div>No tour found</div>; // tour 데이터가 없을 때 처리
  }

  const details = [
    { title: "LOCATION", content: "REPUBLIC OF KOREA" },
    { title: "DURATION", content: `${tour?.duration} DAYS` },
    { title: "DATE", content: tour?.description.subTitle },
    { title: "NUMBER OF SPECIES EXPECTED", content: `${tour?.description.species}+` },
    { title: "MINIMUN GROUP SIZE", content: `${tour?.size} \n EMAIL US FOR SMALLER GROUPS` },
    { title: "GUIDE LANGUAGES", content: tour?.description.climate }
  ];
  return (
    <Box sx={{ marginBottom: '100px', overflow: 'hidden', marginRight: { xs: "0", md: "8%" } }}>
      <Grid container justifyContent='center'
        sx={{ flexDirection: { xs: "column", md: "row" } }}>

        {/* 1사분면 */}
        <Grid item xs={12} md={10} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              width: "100%",
              height: "100%",
            }}>
            <Swiper
              style={{
                width: "100%",
                height: "auto",
                aspectRatio: "1422 / 802",
              }}
              modules={[Pagination, Navigation, Autoplay]}
              loop={true}
              pagination={{ el: '.swiper-pagination', type: 'progressbar' }}
              navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
              autoplay={{ delay: 2000 }}
            >
              {topBirdData?.map((bird) => (
                <SwiperSlide >
                  <Box
                    component="img"
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain"
                    }}
                    src={readImageFromS3(bird.bucketUrl)}
                  ></Box>
                </SwiperSlide>
              ))}

              <div className="swiper-pagination"></div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </Swiper>

          </Box>

        </Grid>

        {/* 2사분면 */}
        <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              width: "100%",
              height: "100%",
              p: '30px 0px 30px 30px',
              pb: 0
            }}
          >
            <Box>
              <Typography sx={{ fontSize: { xs: "18px", md: "22px", xl: "26px" }, }} align="left" fontFamily="MazzardM-Bold">
                {tour?.title}
              </Typography>

              {details.map((item, index) => (
                <Box my={3}>
                  <Typography fontSize="16px" color="#979797" fontFamily="MazzardM-Regular" whiteSpace='pre-line'>
                    {item.title}
                  </Typography>
                  <Typography fontSize="16px" fontFamily="MazzardM-Regular" whiteSpace='pre-line'>
                    {item.content}
                  </Typography>
                </Box>
              ))}
            </Box>
            {/* <Button
              sx={{
                fontFamily: "MazzardM-Bold",
                color: "white",
                backgroundColor: "black",
                borderRadius: "0",
                fontSize: '15px',
                lineHeight: '20px',
                width: '100%',
                maxHeight: "40px",
                aspectRatio: '292 / 60',
                '&:hover': {
                  backgroundColor: '#D9D9D9'
                },
              }}
            >
              JOIN WAITLIST
            </Button> */}
          </Box>
        </Grid>

        {/* 3사분면 */}
        <Grid item xs={12} md={10} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

          <Box>
            <Divider
              sx={{
                marginY: 2,
                border: 1,
                color: "#D9D9D9",
                width: '100%'
              }}
            />
            < TourTab key={tour?.id} {...tour} />
          </Box>
          <Divider
            sx={{
              border: 1,
              color: "#D9D9D9",
              height: "100%"
            }}
            orientation="vertical"
          />

        </Grid>

        {/* 4사분면 */}
        <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        </Grid>
      </Grid >
    </Box >
  );
}
export default TourPost;
