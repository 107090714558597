import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Stack, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Typography } from "@mui/material";
import { theme } from "../../../../theme-colors/theme";
import { Itinerary } from "../../../../api/exploreApi";
import { styled } from '@mui/system';

// icon
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CircleIcon from '@mui/icons-material/Circle';
import { SvgIcon } from '@mui/material';

import BirdCard from "./BirdCard";


const ArrowButton = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
}));


type ItineraryTabProps = {
  itinerary: Itinerary
};


export default function ItineraryTab({ itinerary }: ItineraryTabProps) {
  const [value, setValue] = React.useState('1');

  const leftArrowColor = parseInt(value) == 1 ? 'lightgray' : 'black';
  const rightArrowColor = parseInt(value) == itinerary.itineraryDays.length ? 'lightgray' : 'black';


  const themeChecker = useTheme();
  const isXs = useMediaQuery(themeChecker.breakpoints.down('sm'));  // xs 이하인지 확인

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box my={5} sx={{ mx: { xs: '5%', sm: '10%' } }} >
      <TabContext value={value} >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
          {/* day 스위치 */}
          <Box justifyContent="space-between" alignItems="center" display='flex' width='100%' maxWidth='100vw'
            sx={{
              backgroundColor: 'white', zIndex: 10,
            }}>
            < ArrowButton style={{ color: leftArrowColor }} onClick={() => setValue((prevValue) => {
              if (parseInt(prevValue) - 1 >= 1) {
                return (parseInt(prevValue) - 1).toString()
              } else
                return prevValue;
            })}>
              <KeyboardArrowLeftIcon sx={{ fontSize: 50 }} />
            </ArrowButton>

            <Box sx={{
              flexShrink: 0,
              width: { xs: '80%', sm: '80%' }
            }}
              display='flex'
              alignContent='center'
              justifyContent='center'>
              <TabList onChange={handleChange} aria-label="lab API tabs"
                TabIndicatorProps={{ style: { display: "none" } }}
                sx={{
                  display: 'flex',
                  '& .MuiTab-root': {
                    fontFamily: 'MazzardM-Medium',
                    textTransform: 'none',
                    color: 'gray',
                  },
                  '& .Mui-selected': {
                    fontFamily: 'MazzardM-Bold',
                    fontWeight: 'bold',
                    color: theme.textColor + '!important',
                  },
                }}>
                {itinerary.itineraryDays.map((data, i) => (
                  <Tab
                    label={
                      isXs ? (
                        <SvgIcon
                          component="svg"
                          viewBox="0 0 24 24"
                          sx={{
                            width: 15,
                            height: 15,
                            color: 'currentColor',  // currentColor는 부모의 color 속성을 따릅니다.
                          }}
                        >
                          <circle cx="10" cy="10" r="6" />
                        </SvgIcon>
                      ) : (
                        "Day " + data.sequence
                      )
                    }
                    value={(i + 1).toString()}
                    sx={{
                      minWidth: 'auto',  // 최소 너비를 자동으로 설정하여 길어지는 현상 방지
                      width: 'auto',      // 너비 자동 설정
                    }}
                  />
                ))}
              </TabList>
            </Box>


            <ArrowButton style={{ color: rightArrowColor }} onClick={() => setValue((prevValue) => {
              if (parseInt(prevValue) + 1 <= itinerary.itineraryDays.length) {
                return (parseInt(prevValue) + 1).toString()
              } else
                return prevValue;
            })}>
              <KeyboardArrowRightIcon sx={{ fontSize: 50 }} />
            </ArrowButton>
          </Box>

          {
            itinerary.itineraryDays.map((data, i) => (
              <TabPanel
                sx={{ width: { xs: '100%', sm: '100%' }, px: 0 }}
                value={(i + 1).toString()} >
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <Box sx={{ backgroundColor: 'black', width: '30%', height: { xs: 50, md: 80 }, color: 'white', }} alignContent='center' justifyContent='center'>
                    <Typography fontFamily="MazzardM-Medium" align="center" sx={{ fontSize: { xs: '14px', md: '18px' } }}>DAY {data.sequence}</Typography>
                  </Box>
                  <Box sx={{ backgroundColor: '#F9F9F9', width: '70%', height: { xs: 50, md: 80 }, }} alignContent='center' justifyContent='center'>
                    <Typography fontFamily="MazzardM-Medium" align="left" paddingLeft="5%" sx={{ fontSize: { xs: '14px', md: '18px' } }}>{data.title}</Typography>
                  </Box>
                </Stack>
                <Timeline
                  sx={{
                    padding: 0,
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,

                    }
                  }}
                >

                  {
                    data.schedules.map((day, i) => (
                      <TimelineItem>
                        <TimelineSeparator sx={{ display: { xs: 'none', sm: 'flex' } }}>
                          <TimelineDot
                            sx={{
                              width: '30px',
                              height: '30px',
                              padding: 0, borderWidth: 0, boxShadow: 0
                            }}
                          >
                            <img src="./../store/logo_b.png"
                              style={{
                                width: '100%',
                                height: '100%',
                              }} />
                          </TimelineDot>
                          <TimelineConnector />

                        </TimelineSeparator>
                        <TimelineContent sx={{
                          maxWidth: "100%",
                          padding: 0,
                          paddingLeft: { xs: '0', sm: '16px' },
                          paddingTop: { xs: '0', sm: '6px' }

                        }}>
                          {
                            day.type === "attraction" ? (
                              <>
                                <Box height="50px" alignContent="start" justifyContent="start" alignItems='center'
                                  sx={{
                                    display: { xs: 'none', sm: 'flex' }
                                  }}>
                                  <Typography fontFamily="MazzardM-Medium" sx={{ fontWeight: 600 }}>{day.name}</Typography>
                                </Box>
                                <Box sx={{ border: "0px solid", borderColor: "#D9D9D9", paddingY: "10px" }}>
                                  <Typography sx={{ fontFamily: "ACaslonPro-Regular", whiteSpace: 'pre-line', textAlign: { xs: 'left', sm: 'justify' }, lineHeight: { xs: '1.2', md: '1.5' } }}>
                                    {day.destination}
                                  </Typography>
                                </Box>
                                <Box mt="20px">
                                  <Typography fontFamily="MazzardM-Medium">TARGET BIRDS</Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 1,
                                      py: 1,
                                      width: "100%",
                                    }}
                                  >
                                    {day.targetBirds?.map((topBirdId: string) => (
                                      <BirdCard topBirdId={topBirdId} ></BirdCard>
                                    ))}
                                  </Box>
                                </Box>
                              </>
                            ) :
                              <Box height="30px" alignContent="center">
                                <Typography fontFamily="MazzardM-Medium" sx={{ fontWeight: 600 }}>{day.name}</Typography>
                              </Box>
                          }
                        </TimelineContent>
                      </TimelineItem>
                    ))
                  }
                </Timeline>
              </TabPanel>
            ))
          }
        </Box >
      </TabContext >
    </Box >
  );
}
