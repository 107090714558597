import Box from "@mui/material/Box";
import { Avatar, Typography } from "@mui/material";

import { IBird, ITour, getTopBirdById } from "../../../../api/exploreApi";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import Timeline from "@mui/lab/Timeline";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from "@mui/lab";

export default function TourGuide(tour: ITour) {


    const { isLoading: birdLoading, data: topBirdData } = useQuery<IBird[]>({
        queryKey: ["ovv" + tour.id],
        queryFn: () => getTopBirdById(tour.id),
    });
    const storyDaehwan = `After studying algae physiology at university and working as a high school biology teacher, he developed an interest in wildflowers, insects, and mushrooms. However, in January 2002, he accidentally spotted a bird, was captivated by its beauty, and has been birding ever since. \n
                            At that time, Korea's birding environment was very rudimentary, with little literature available for reference. Despite this, he actively pursued birding, photographing birds for publications and educational purposes. Over the years, he has authored five birding books and led various birding-related educational activities. \n
                            With support from the Ministry of Environment, he ran a birding education program for four years. Additionally, with support from Incheon City, he conducted bird monitoring on Incheon's islands and carried out foundational research for birding tourism. \n
                            He also founded the Incheon Birding Association, a civic organization dedicated to bird conservation, birding tourism, and birding education. For nine years, he led the Winter Bird Census, a project promoted by the Ministry of Environment.
                        `

    const storyLiam = `Liam has been a passionate birdwatcher for over 20 years, with a deep love for the diverse avian species found in South Korea. His birdwatching journey began in Cheonsu Bay, Seosan, where he became captivated by the region’s unique birdlife.\n
                            Having observed more than 430 bird species across South Korea, he has developed an exceptional understanding of the country’s bird habitats and migration patterns.\n
                            Liam's experience extends beyond local birdwatching—he has participated in international birding tours, including expeditions in Mongolia, Thailand, and the Philippines.\n
                            With his meticulous planning and vast knowledge of bird species, Liam offers birdwatchers a truly unforgettable experience, guiding them to encounter both common and rare birds across the beautiful landscapes of South Korea.
                            `

    const storyOscar = `Oscar is a passionate birdwatcher and expert bird guide, with over 20 years of experience in the field. He fell in love with birds after his first sighting in 2002 and has been actively involved in birding ever since.\n
                            Oscar has observed numerous bird species in South Korea, and his extensive knowledge of the country’s birdlife has made him an invaluable guide for both beginners and experienced birdwatchers. He specializes in interpreting bird behavior and sharing fascinating stories about various species.\n
                            Oscar’s expertise extends beyond local birdwatching—he has also participated in international birding events, including the Bird Races in both domestic and international settings.\n
                            With his years of experience and passion for birds, Oscar offers birdwatchers a unique and unforgettable experience. He guides visitors through Korea’s most beautiful bird habitats, providing insights into the behavior and characteristics of the birds that call this land home.
                            `
    return <>
        <Box my={5} sx={{ mx: { xs: '5%', sm: '10%' } }}>
            <Timeline
                sx={{
                    padding: 0,
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    }
                }}>
                <ProfileCard name="Kim, Dae-hwan" role="Birder & Guide" url="/store/guides/daehwan.png"
                    story={storyDaehwan} />
                <ProfileCard name="Park, Sang-jun (Liam)" role="Birder & Guide" url="/store/guides/sangjun.png"
                    story={storyLiam} />
                <ProfileCard name="Cho, Heung-sang (Oscar)" role="Birder & Guide" url="/store/guides/oscar.png"
                    story={storyOscar} />

            </Timeline>
        </Box>
    </>
}

type ProfileProps = {
    name: string;
    role: string;
    story: string;
    url: string;
};
function ProfileCard({ name, role, url, story }: ProfileProps) {
    return (

        <TimelineItem>
            <TimelineSeparator sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <TimelineDot
                    sx={{
                        width: '30px', height: '30px',
                        padding: 0, borderWidth: 0, boxShadow: 0
                    }}
                >
                    <img src="./../store/logo_b.png"
                        style={{
                            width: '100%',
                            height: '100%',
                        }} />
                </TimelineDot>
                <TimelineConnector />

            </TimelineSeparator>
            <TimelineContent sx={{
                maxWidth: "100%",
                padding: 0,
                paddingLeft: { xs: '0', sm: '16px' },
                paddingTop: { xs: '0', sm: '6px' },
                marginBottom: '30px'

            }}>
                <Box sx={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}>
                    <Box alignContent="center" justifyContent="center" alignItems='center' my='10px'>
                        <Typography fontFamily="MazzardM-Medium" sx={{
                            fontWeight: 600,
                            fontSize: { xs: "20px", md: "22px" },
                        }}>
                            {name}
                        </Typography>
                    </Box>
                    <Box sx={{ flexDirection: { xs: "column", md: "row" }, display: "flex" }}>
                        <Box >
                            <Avatar
                                alt={name}
                                src={url} // 여기에 실제 이미지 URL을 넣으세요.
                                sx={{
                                    width: '10vw',
                                    height: '10vw',
                                    minWidth: '200px',
                                    minHeight: '200px',
                                    marginBottom: 2,
                                }} />
                        </Box>
                        <Box sx={{ paddingX: { xs: "0", md: "50px" }, flexDirection: "row", display: "flex", whiteSpace: 'pre-line' }}>
                            <Typography sx={{ fontFamily: { xs: "ACaslonPro-Regular" }, whiteSpace: 'pre-line', textAlign: { xs: 'left', sm: 'justify' }, lineHeight: { xs: '1.2', md: '1.5' } }}>
                                {story}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </TimelineContent>
        </TimelineItem>


    );
}