import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { IBird, ITour, getTopBirdById } from "../../../../api/exploreApi";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import Timeline from "@mui/lab/Timeline";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from "@mui/lab";

export default function Overview(tour: ITour) {


    const { isLoading: birdLoading, data: topBirdData } = useQuery<IBird[]>({
        queryKey: ["ovv" + tour.id],
        queryFn: () => getTopBirdById(tour.id),
    });

    const details = [
        { title: "HABITS COVERED", content: tour.description.habits },
        { title: "EXPECTED CLIMATE", content: tour.description.climate },
        { title: "MAX GROUP SIZE", content: tour.size },
        { title: "TOUR PACE & WALKING", content: tour.description.pace },
        { title: "ACCOMODATION", content: tour.description.accommodation },
        { title: "EASE OF BIRDING", content: tour.description.ease },
        { title: "NUMBER OF SPECIES EXPECTED", content: tour.description.species },
        { title: "OTHER ATTRACTIONS", content: tour.description.others },
        { title: "PHOTOGRAPHIC OPPORTUNITIES", content: tour.description.photo },
    ];

    return <>
        <Box my={5} sx={{ mx: { xs: '5%', sm: '10%' } }}>
            <Timeline
                sx={{
                    padding: 0,
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    }
                }}>
                <TimelineItem>
                    <TimelineSeparator sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <TimelineDot
                            sx={{
                                width: '30px', height: '30px',
                                padding: 0, borderWidth: 0, boxShadow: 0
                            }}
                        >
                            <img src="./../store/logo_b.png"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }} />
                        </TimelineDot>
                        <TimelineConnector />

                    </TimelineSeparator>
                    <TimelineContent sx={{
                        maxWidth: "100%",
                        padding: 0,
                        paddingLeft: { xs: '0', sm: '16px' },
                        paddingTop: { xs: '0', sm: '6px' }

                    }}>
                        <Box alignContent="center" justifyContent="center" alignItems='center' my='10px'>
                            <Typography fontFamily="MazzardM-Medium" sx={{
                                fontWeight: 600,
                                fontSize: { xs: "20px", md: "22px" },
                            }}>
                                {tour.description.habits}
                            </Typography>
                        </Box>
                        <Box sx={{ border: "0px solid", borderColor: "#D9D9D9", paddingY: "20px" }} >
                            <Typography sx={{ fontFamily: { xs: "ACaslonPro-Regular" }, whiteSpace: 'pre-line', textAlign: { xs: 'left', sm: 'justify' }, lineHeight: { xs: '1.2', md: '1.5' } }}>
                                {tour.description.overview}
                            </Typography>
                        </Box>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </Box>
    </>
}