import styled from "styled-components";
import { theme } from "../../theme-colors/theme";
import { Box, Divider, Typography } from "@mui/material";
import { UseFormRegister, useForm } from "react-hook-form";
import { Button } from "@mui/joy";
import sendEmail from "../pages/contact/sendEmail";

interface IField {
  width: string;
  height: string;
  border: string;
  margin?: string;
}
const Field = styled.input<IField>`
  border-color: ${(props) => props.theme.textColor};
  border-style: solid;
  border-width: ${(props) => props.border};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;

const onSubmit = (data: FormData) => {
  console.log(data);
  sendEmail(data["E-Mail"], data.Name + " / " + data.Phone, data.message);
};
interface FormData {
  Name: string;
  'E-Mail': string;
  Phone: string;
  message: string;
}
function Contact() {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();

  return (
    <Box sx={{ alignContent: "center", justifyContent: "center", display: "flex", flexDirection: "column", mx: { xs: '20px', md: "25%" } }}>
      <Box>
        <Typography align="center" fontFamily="MazzardM-Bold" my={3}
          sx={{
            fontSize: { xs: '16px', md: "20px" }
          }}>
          SPARKBIRD KOREA : YOUR GATEWAY TO KOREA'S AVIAN WONDERS
        </Typography>
        <Typography align="center" fontFamily="MazzardM-Regular" mb={10}
          sx={{
            fontSize: { xs: '14px', md: "20px" }
          }}>
          Got questions about our tours or need assistance planning your birdwatching journey?
          <br />
          At SparkBird Korea, we're passionate about connecting you with Korea's unique and vibrant birdlife.
          <br />
          Leave your name and email below, and our dedicated team will respond promptly.
          <br />
          Let's make your birdwatching dreams take flight!
        </Typography>
      </Box>

      <Box sx={{ alignContent: "center", justifyContent: "center", px: { xs: '0', sm: '10%' } }} >
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField field="Name" placeholder="Your name" register={register} />
          <InputField field="E-Mail" placeholder="Email address to receive a reply" register={register} />
          <InputField field="Phone" placeholder="+44 7123 456789" register={register} />
          <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%", my: '20px' }}>
            <Typography align="center" fontFamily="MazzardM-Regular"
              sx={{ fontSize: { xs: '14px', md: "15px" } }}>Message</Typography>
            <Field placeholder="  Please write your inquiry." width="80%" height="250px" border="1px"  {...register('message')} />
          </Box>


          <Button
            type="button" // 기본 submit 방지
            onClick={(e) => {
              if (window.confirm("Would you like to submit the email inquiry?")) {
                // 여기서 실제 form 제출
                const form = e.currentTarget.closest("form"); // form 태그 찾아서
                form?.requestSubmit(); // 강제 제출
                setTimeout(() => {
                  form?.reset(); // form 내용 클리어
                }, 100); // 잠시 후 실행
              }
            }}
            sx={{
              fontFamily: "MazzardM-Regular",
              color: theme.backgroundColor,
              backgroundColor: theme.btnColor,
              borderRadius: "30px",
              height: "40px",
              fontSize: { xs: '14px', md: "15px" },
              width: '100%',
              mb: '100px',
              '&:hover': {
                backgroundColor: '#D9D9D9'
              },
            }}
          >
            Submit
          </Button>
        </form>

      </Box >
    </Box >
  );
}
export default Contact;


type InputProps = {
  field: keyof FormData;
  placeholder: string;
  register: UseFormRegister<FormData>;
};
function InputField({ field, placeholder, register }: InputProps) {
  return (
    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", flexDirection: "column", width: "100%", marginTop: '20px' }}>
      <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%" }}>
        <Typography align="center" fontFamily="MazzardM-Regular" sx={{ fontSize: { xs: '14px', md: "16px" } }}>{field}</Typography>
        <Field
          type="text" id={field} {...register(field, { required: `${field} is required` })}
          placeholder={placeholder} width="80%" height="25px" border="0px" />
      </Box>
      <Divider
        sx={{
          border: 1,
          color: '#D9D9D9',
        }}
      />
    </Box>);
}
