import React from "react";
import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { readImageFromS3 } from "../../../functions/readImageFromS3";


export type ImageItem = {
    bucketUrl: string;
    name: string;
};

interface InfiniteSliderProps {
    images: ImageItem[];
}
const InfiniteSlider: React.FC<InfiniteSliderProps> = ({ images }) => {
    return (
        <div style={{ overflow: "hidden", width: "100%", height: "auto" }}>
            {/* Row 1: Move to the right */}
            <Box
                sx={{
                    display: "flex",
                    animation: "scroll-right 20s linear infinite",
                    whiteSpace: "nowrap",
                }}
                onMouseEnter={(e) => e.currentTarget.style.animationPlayState = 'paused'}
                onMouseLeave={(e) => e.currentTarget.style.animationPlayState = 'running'}
            >
                {images.slice(0, 15).map((image, index) => (
                    <Box>
                        <Box
                            key={`row1-${index}`}
                            sx={{
                                margin: "0 5px",
                                display: "flex",
                                flexDirection: "column", // 이미지와 텍스트를 세로로 정렬
                                alignItems: "flex-start", // 왼쪽 정렬,
                                width: { xs: "200px", sm: "486px" },
                                height: "auto"
                            }}
                        >
                            <img
                                src={readImageFromS3(image.bucketUrl)}
                                alt={image.name}
                            />
                        </Box>

                        < Typography sx={{
                            fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" },
                        }}
                            align="left" fontFamily="MazzardM-Light" my={1}>
                            {image.name}
                        </Typography>
                    </Box>

                ))}
            </Box>

            {/* Row 2: Move to the left */}
            <Box
                style={{
                    display: "flex",
                    animation: "scroll-left 25s linear infinite",
                    whiteSpace: "nowrap",
                    marginTop: "20px",
                }}
                onMouseEnter={(e) => e.currentTarget.style.animationPlayState = 'paused'}
                onMouseLeave={(e) => e.currentTarget.style.animationPlayState = 'running'}
            >
                {images.slice(15, 30).map((image, index) => (

                    <Box>
                        <Box
                            key={`row1-${index}`}
                            sx={{
                                margin: "0 5px",
                                display: "flex",
                                flexDirection: "column", // 이미지와 텍스트를 세로로 정렬
                                alignItems: "flex-start", // 왼쪽 정렬,
                                width: { xs: "200px", sm: "486px" },
                                height: "auto"
                            }}
                        >
                            <img
                                src={readImageFromS3(image.bucketUrl)}
                                alt={image.name}
                            />
                        </Box>

                        < Typography sx={{
                            fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" },
                        }}
                            align="left" fontFamily="MazzardM-Light" my={1}>
                            {image.name}
                        </Typography>
                    </Box>
                ))}
            </Box>

            {/* CSS Keyframes */}
            <style>
                {`
          @keyframes scroll-right {
            from {
              transform: translateX(0%);
            }
            to {
              transform: translateX(-100%);
            }
          }

          @keyframes scroll-left {
            from {
              transform: translateX(-100%);
            }
            to {
              transform: translateX(0%);
            }
          }
        `}
            </style>
        </div >
    );
};

export default InfiniteSlider;
