import AWS from 'aws-sdk';
import { send } from 'process';
import { awsConfig } from '../../../aws-config';

AWS.config.update({
    region: awsConfig.region,
    credentials: {
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
    }
});

const ses = new AWS.SES();

const sendEmail = async (from: string, subject: string, body: string) => {
    const paramsForCompany = {
        Destination: {
            ToAddresses: ['share@sparkbirdkorea.com'],
        },
        Message: {
            Body: {
                Text: {
                    Data: `From : ${from}
User information : ${subject}
===================================================== \n\n` + body +
                        `\n\n
© [2025] SparkBird Korea, Inc. All rights reserved.` },
            },
            Subject: { Data: `[예약 문의 ${formattedDate}] ` + subject },
        },
        Source: 'share@sparkbirdkorea.com', // 보내는 이메일 주소
    };

    const paramsForCustomer = {
        Destination: {
            ToAddresses: [from],
        },
        Message: {
            Body: {
                Text: {
                    Data: `Hello, Thank you for reaching out to SparkbirdKorea.
We will respond to your inquiry within 3 business days.
===================================================== \n\n
Your message : \n`
                        + body +
                        `\n\n
Sincerely,
The SparkBird Korea Team\n
© [2025] SparkBird Korea, Inc. All rights reserved.`
                },
            },
            Subject: { Data: `[Your inquiry to SparkbirdKorea] ` + subject },
        },
        Source: 'share@sparkbirdkorea.com', // 보내는 이메일 주소
    };

    try {
        await ses.sendEmail(paramsForCompany).promise();
        console.log('Email sent successfully - company');
    } catch (error) {
        console.error('Error sending email - company : ', error);
    }

    try {
        await ses.sendEmail(paramsForCustomer).promise();
        console.log('Email sent successfully - customer');
    } catch (error) {
        console.error('Error sending email - customer :', error);
    }

};
const formattedDate = new Intl.DateTimeFormat("ko-KR", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
})
    .format(new Date())
    .replace(/\//g, ".") // 날짜 부분 '/'을 '.'으로 변경
    .replace(",", ""); // 쉼표 제거
export default sendEmail;