import { Outlet, useLocation, useNavigate } from "react-router-dom";

import getPathHeader from "../../functions/getPathHeader";


import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

import { theme } from "../../theme-colors/theme";
import NavigationMenu from "../../enum/NavigationMenu";
import MenuIcon from '@mui/icons-material/Menu';

import Drawer from '@mui/material/Drawer';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";


const languages = [
  'English',
  '한국어'
];


interface NavigationBarProps {
  currentPath: string;
}


export default function NavigationBar({ currentPath }: NavigationBarProps) {
  const navigate = useNavigate();
  const onMainClick = () => {
    navigate("/");
  };
  const location = useLocation();
  const pathHeader = getPathHeader(location.pathname);

  //hover event
  const [isHovered, setIsHovered] = React.useState(false);
  const isActive = (!(["Spark Bird Tour", "ABOUT-US"].includes(pathHeader)) || isHovered);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {NavigationMenu.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton>
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontFamily: 'MazzardM-Medium',  // primary 텍스트 폰트 설정
                    fontSize: '14px',               // primary 텍스트 폰트 크기
                    // fontWeight: 600,                // primary 텍스트 폰트 두께
                    color: theme.textColor,          // primary 텍스트 색상 설정
                  },
                }}
                onClick={() => {
                  navigate(`/${item.replace(/ /g, '-')}`);
                }}
              >
                {item}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}

      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        backgroundColor: { xs: pathHeader == 'Spark Bird Tour' ? 'transparent' : theme.backgroundColor, sm: isActive ? theme.backgroundColor : 'transparent' },
        p: 0,
        boxShadow: 0,
        borderBottom: 0.5,
        borderColor: { xs: pathHeader == 'Spark Bird Tour' ? 'transparent' : '#E8E8E8', sm: isActive ? '#E8E8E8' : 'transparent' },
        transition: 'background-color 0.4s, color 0.4s, border-color 0.4s',
        zIndex: 10,
      }}
    >
      <Toolbar sx={{
        height: { xs: "100px", sm: "120px" },
        display: "flex",
        justifyContent: "flex-start",
        borderBottom: 1,
        py: '0 !important',
        px: '5% !important',
        borderColor: 'transparent',
      }}>
        {/* 모바일 아이콘 */}
        <Box sx={{
          justifyContent: 'flex-start',
          flex: "1",
          visibility: {
            xs: 'visible',
            sm: 'hidden'
          },
          display: {
            xs: 'flex',
            sm: 'none'
          },
          height: "30px",
          width: "30px",
        }}>
          <Button onClick={toggleDrawer(true)} sx={{ minWidth: 0, padding: 0 }}>
            <MenuIcon fontSize="medium" sx={{ color: pathHeader == 'Spark Bird Tour' ? "white" : "black" }} />
          </Button>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
        </Box>
        <Box
          onClick={onMainClick}
          sx={{
            cursor: 'pointer',
            justifyContent: 'center',
            flex: "1",
            visibility: {
              xs: 'visible',
              sm: 'hidden'
            },
            display: {
              xs: 'flex',
              sm: 'none'
            },
            height: "68.96px",
            width: "102px",
          }}
        >
          <img src={pathHeader == 'Spark Bird Tour' ? `/store/logo_w.png` : `/store/logobig_b.png`} />

        </Box>
        <Box
          sx={{
            justifyContent: 'end',
            flex: "1",
            visibility: {
              xs: 'visible',
              sm: 'hidden'
            },
            display: {
              xs: 'flex',
              sm: 'none'
            },
            height: "68.96px",
            width: "102px",
          }}
        >

        </Box>


        {/* 데스크탑용  */}
        <Box
          sx={{
            flex: "1",
            justifyContent: "flex-start",
            display: { xs: "none", sm: "flex" },
          }}
        >
          {NavigationMenu.map((item) => (
            <Button
              key={item}
              sx={{
                color: isActive ? theme.textColor : theme.backgroundColor,
                fontFamily: 'MazzardM-Regular',
                fontSize: "14px",
                textTransform: 'none' // 대문자 변환을 방지
              }}
              onClick={() => {
                navigate(`/${item.replace(/ /g, '-')}`);
              }}
            >
              {item}
            </Button>
          ))}
        </Box>


        <Box
          onClick={onMainClick}
          sx={{
            justifyContent: "center",
            flex: "1",
            visibility: {
              xs: 'hidden',
              sm: 'visible'
            },
            display: {
              xs: 'none',
              sm: 'flex'
            },
            height: "92px",
            my: "20px",
            cursor: "pointer"
          }}
        >
          <img src={isActive ? `/store/logobig_b.png` : `/store/logo_w.png`} />
        </Box>

        {/* 공갈 */}
        <Box
          sx={{
            flex: "1",
            visibility: {
              xs: 'hidden',
              sm: 'visible'
            },
            display: {
              xs: 'none',
              sm: 'flex'
            },
          }}
        >

        </Box>

        {/* 언어, 검색 버튼 */}
        {/* <Box
          sx={{
            flexBasis: 0,
            flexGrow: 1, justifyContent: "center",
            display: { xs: "none", sm: "flex" },
            gap: 4
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <img
              src={isActive ? `/store/language.png` : `/store/language_w.png`}
              alt="SparkBird"
              style={{ width: "25px", height: "25px" }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <img
              src={isActive ? `/store/search.png` : `/store/search_w.png`}
              key={location.state}
              style={{ width: "25px", height: "25px" }}
            />
          </Box>
        </Box> */}
      </Toolbar>

      {/* </Container> */}
    </AppBar >
  );
}
